/* .lower__list
------------------------------------------------- */
.l-lowerList {
  position: relative;
  margin-bottom: 88px;
  color: $color-glay;
  @include media(pc-s) {
    margin-bottom: resp(88px);
  }
  @include media(sp) {
    margin-bottom: 60px;
  }

  &.-flex {
    display: flex;
    justify-content: center;
  }


  &.-mb {
    margin-bottom: 100px;
    @include media(pc-s) {
      margin-bottom: resp(100px);
    }
    @include media(sp) {
      margin-bottom: 60px;
    }
  }

  &.-dev {
    margin-bottom: 100px;
    @include media(pc-s) {
      margin-bottom: resp(100px);
    }
    @include media(sp) {
      margin-bottom: 24px;
    }
  }
}

.lowerList__container {

  // 共通
  .lowerList__item {
    font-size: 2.2rem;
    line-height: 2;
    @include media(pc-s) {
      font-size: resp(2.2rem);
    }
    @include media(sp) {
      font-size: 1.3rem;
    }

    &:first-child {
      padding-top: 0 !important;
    }
    &:last-child {
      padding-bottom: 0 !important;
    }
  }

  // 中央揃え時
  &.-center {

    .lowerList__item {
      padding: 48px 0;
      border-top: 1px dotted $color-glay;
      @include media(pc-s) {
        padding: resp(48px) 0;
      }
      @include media(sp) {
        padding: 36px 0;
      }

      &:first-child {
        border-top: none;
      }
    }
  }

  // 左揃え時
  &.-left {
    display: inline-block;
    text-align: left;

    .lowerList__item {
      padding-left: 1em;
      text-indent: -1em;
      @include media(pc-s) {

      }
      @include media(sp) {

      }
    }
  }
}
