.button {
  $this: &;
  display: block;
  max-width: 572px;
  margin: 0 auto;
  padding: 25px 0;
  border: 1px solid $color-main;
  font-size: 2rem;
  color: $color-main;
  @include media(pc-s) {
    max-width: resp(572px);
    padding: resp(25px) 0;
    font-size: resp(2rem);
  }
  @include media(sp) {
    padding: 12px 0;
    font-size: 1.6rem;
  }

  &__text {
    position: relative;
    @include media(sp) {
      display: block;
      line-height: 1.4;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -7px;
      transform: translate(100%, -50%);
      background-repeat: no-repeat;
      background-image: url(../images/common/icon/arrow_right03.svg);
      width: 24px;
      height: 24px;
      @include media(pc-s) {
        right: resp(-7px);
        width: resp(24px);
        height: resp(24px);
      }
      @include media(sp) {
        right: 14px;
        transform: translate(0, -50%);
        width: 18px;
        height: 18px;
      }
    }
  }

  &:hover {
    background-color: $color-main;
    color: $color-wh;
  }
}
