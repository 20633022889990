.l-container {

  &.-mb40 {
    margin-bottom: 40px;
    @include media(pc-s) {
      margin-bottom: resp(40px);
    }
    @include media(sp) {

    }
  }

  &.-mb54 {
    margin-bottom: 54px;
    @include media(pc-s) {
      margin-bottom: resp(54px);
    }
    @include media(sp) {
      margin-bottom: 32px;
    }
  }

  &.-mb68 {
    margin-bottom: 68px;
    @include media(pc-s) {
      margin-bottom: resp(68px);
    }
    @include media(sp) {
      margin-bottom: 44px;
    }
  }

  &.-mb80 {
    margin-bottom: 80px;
    @include media(pc-s) {
      margin-bottom: resp(80px);
    }
    @include media(sp) {
      margin-bottom: 60px;
    }
  }

  &.-mb100 {
    margin-bottom: 100px;
    @include media(pc-s) {
      margin-bottom: resp(100px);
    }
    @include media(sp) {
      margin-bottom: 60px;
    }
  }

  &.-mb130 {
    margin-bottom: 130px;
    @include media(pc-s) {
      margin-bottom: resp(130px);
    }
    @include media(sp) {
      margin-bottom: 60px;
    }
  }

  &.-spSmall {
    @include media(sp) {
      margin-bottom: 32px !important;
    }
  }
}
