.sub-title {
  font-size: 2.9rem;
  font-weight: bold;
  color: $color-glay;
  @include media(pc-s) {
    font-size: resp(2.9rem);
  }
  @include media(sp) {
    font-size: 1.6rem;
  }
}
