/* #### Generated By: http://www.cufonfonts.com #### */

// @font-face {
//   font-family: 'フォント名';
//   font-style: normal;
//   font-weight: normal;
//   src:
//   local('フォント名前※日本語可'),//localを先に指定する
//   url('../fonts/○○.woff') format('woff'),
//   url('../fonts/○○.ttf') format('ttf');
//   font-display: swap;
// }
//


@font-face {
  font-family: 'Affogato-Medium';
  font-style: normal;
  font-weight: normal;
  src:
  local('Affogato-Medium'),
  url('../fonts/Affogato-Medium.woff') format('woff'),
  url('../fonts/Affogato-Medium.ttf') format('truetype');
  font-display: swap;
}
