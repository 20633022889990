/* footer
------------------------------------------------ */
.footer{
  background-color: $color-glay;
  padding: 108px 0 28px;
  @include media(pc-s) {
    padding: resp(108px) 0 resp(28px);
  }
  @include media(sp) {
    display: flex;
    justify-content: center;
    padding: 68px 0 15px;
  }

  .footer-container {
    display: flex;
    margin-bottom: 154px;
    margin-left: -140px;
    @include media(pc-s) {
      margin-bottom: resp(154px);
      margin-left: -100px;
    }
    @include media(tb) {
      margin-left: -80px;
    }
    @include media(sp) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 70px;
      margin-left: 0;
    }
  }

  &__logo,
  &__list {
    width: calc(33.333% - 140px);
    margin-left: 140px;
    @include media(pc-s) {
      width: calc(33.333% - 100px);
      margin-left: 100px;
    }
    @include media(tb) {
      width: calc(33.333% - 80px);
      margin-left: 80px;
    }
    @include media(sp) {
      width: 100%;
      margin-left: 0;
    }
  }

  &__logo {
    width: 355px;
    @include media(pc-s) {
      width: resp(355px);
    }
    @include media(sp) {
      width: 220px;
      margin: 0 auto 50px;
    }

    & > a {

      &:hover {
        opacity: .7;
      }
    }
  }

  &__list {

    @include media(sp) {
      padding-left: 14px;

      &:first-of-type {
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid $color-wh;
      }
    }
  }

  &__item {
    position: relative;
    margin-bottom: 42px;
    padding-left: 14px;
    font-size: 1.8rem;
    @include media(pc-s) {
      margin-bottom: resp(42px);
      padding-left: resp(14px);
      font-size: resp(1.8rem);
    }
    @include media(sp) {
      margin-bottom: 30px;
      padding-left: 12px;
      font-size: 1.3rem;
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      background-color: $color-wh;
      border-radius: 50%;
      @include media(pc-s) {
        width: resp(4px);
        height: resp(4px);
      }
      @include media(sp) {

      }
    }

    & > a {
      display: block;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .copyright {
    font-size: 1.5rem;
    text-align: center;
    @include media(pc-s) {
      font-size: resp(1.5rem);
    }
    @include media(sp) {

    }
  }
}
