/* 下層ページ共通
------------------------------------------------ */

/* header
------------------------------------------------ */
body.lower {
  .header {
    color: $color-bk;

    &__logo {

      .logoSvg {
        fill: $color-bk;
      }
    }

    .gnav {

      &__item {
        border-right: 1px solid $color-bk;
      }
    }

    .drawerBtn {

      &::after {
        color: $color-bk;
      }

      & > span {
        background-color: $color-bk;
      }
    }
  }
}
