// Firefox
@-moz-document url-prefix() {
	.topAbout {
		background-color: rgba($color-bk, .7);
	}
}

// IE


// Edge
_:-ms-lang(x)::backdrop, .topAbout {
  background-color: rgba($color-bk, .7);
}
