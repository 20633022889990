.head-title {
  color: $color-main;

  &.-one {
    font-size: 3.2rem;
    font-weight: bold;
    @include media(pc-s) {
      font-size: resp(3.2rem);
    }
    @include media(sp) {
      font-size: 1.9rem;
      line-height: 1.6;
    }
  }

  .-en {
    margin-bottom: 34px;
    font-size: 7.6rem;
    font-family: $font-en;
    @include media(pc-s) {
      margin-bottom: resp(34px);
      font-size: resp(7.6rem);
    }
    @include media(sp) {
      margin-bottom: 14px;
      font-size: 3.3rem;
      line-height: 1.1;
    }
  }

  .-ja {
    font-size: 2.1rem;
    color: $color-bk;
    @include media(pc-s) {
      font-size: resp(2.1rem);
    }
    @include media(sp) {
      font-size: 1.3rem;
    }
  }
}
