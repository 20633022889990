.l-section-content {
  padding-top: 148px;
  padding-bottom: 200px;
  color: $color-bk;
  text-align: center;
  @include media(pc-s) {
    padding-top: resp(148px);
    padding-bottom: resp(200px);
  }
  @include media(sp) {
    padding-top: 70px;
    padding-bottom: 100px;
  }

  &.-thinks {
    padding-top: 130px;
    @include media(pc-s) {
      padding-top: resp(130px);
    }
    @include media(sp) {
      padding-top: 60px;
    }
  }

  &.-sm {
    padding-top: 0 !important;
    padding-bottom: 200px;
    @include media(pc-s) {
      padding-bottom: resp(200px);
    }
    @include media(sp) {
      padding-bottom: 100px;
    }
  }

  &.-pb0 {
    padding-bottom: 0 !important;
  }


  &__inner {

  }
}
