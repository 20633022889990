/* 会社概要
------------------------------------------------- */
.l-companyTable {
  margin-bottom: 120px;
  padding: 0 70px;
  @include media(pc-s) {
    margin-bottom: resp(120px);
    padding: 0 resp(70px);
  }
  @include media(tb) {
    padding: 0 30px;
  }
  @include media(sp) {
    margin-bottom: 30px;
    padding: 0;
  }
}

.company-table {
  font-size: 2.2rem;
  color: $color-bk;
  @include media(pc-s) {
    font-size: resp(2.2rem);
  }
  @include media(tb) {
    font-size: 1.4rem;
  }

  tr {
    border-bottom: 1px dotted $color-glay;
    text-align: left;
    @include media(sp) {
      text-align: center;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  th {
    width: 25%;
    padding: 30px 0;
    font-weight: normal;
    line-height: 2;
    color: $color-glay;
    vertical-align: top;
    @include media(pc-s) {
      padding: resp(30px) 0;
    }
    @include media(tb) {
      width: 20%;
    }
    @include media(sp) {
      display: block;
      width: 100%;
      padding: 27px 0 0;
      font-size: 1.6rem;
      line-height: 1;
    }
    @include media(se) {
      font-size: 1.5rem;
    }
  }

  td {
    width: 75%;
    padding: 30px 0;
    line-height: 2;
    @include media(pc-s) {
      width: 75%;
      padding: resp(30px) 0;
    }
    @include media(tb) {
      width: 80%;
    }
    @include media(sp) {
      display: block;
      width: 100%;
      padding: 16px 0 25px;
      font-size: 1.3rem;
    }
    @include media(se) {
      font-size: 1.2rem;
    }
  }
}


// GoogleMAP
.l-companyMap {

}

.company-map {

  iframe {
    width: 100%;
    height: 460px;
    @include media(pc-s) {
      height: resp(460px);
    }
    @include media(sp) {
      height: 200px;
    }
  }
}
