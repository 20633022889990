/* スライドショー
-------------------------------------------- */
.kvImages {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .slide-img {
    height: 100%;

    img {
      max-width: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }
}


/* スマホ横
------------------------------------------------ */
@media only screen and (orientation:landscape) and (max-width:813px){

}
