.title {
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 1.8;
  color: $color-main;
  @include media(pc-s) {
    font-size: resp(3.4rem);
  }
  @include media(sp) {
    font-size: 1.9rem;
  }

  &.-marke {
    line-height: 1.3;
    @include media(sp) {
      line-height: 1.6;
    }
  }

  &.-nega {
    transform: translateY(-60px);
    @include media(pc-s) {
      transform: translateY(-42px);
    }
    @include media(sp) {
      transform: translateY(0);
    }
  }

  &.-small {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1;
    color: $color-main;
    @include media(pc-s) {
      font-size: resp(2.5rem);
    }
    @include media(sp) {
      padding-left: 1em;
      text-indent: -1em;
      font-size: 1.9rem;
      line-height: 1.6;
    }
  }

  &.-gray {
    color: $color-glay;
  }
}
