/* header
------------------------------------------------ */
.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  z-index: 10;
  @include media(pc-s) {
    height: resp(100px);
  }
  @include media(sp) {
    height: 70px;
  }

  &__logo {
    width: 310px;
    margin-left: 2%;
    z-index: 101;
    @include media(pc-s) {
      width: resp(310px);
    }
    @include media(tb) {
      margin-left: 3%;
    }
    @include media(sp) {
      width: 180px;
      margin-left: 7%;
    }

    .logoSvg {
      fill: $color-wh;
      transition: .4s;

      &:hover {
        opacity: .6;
      }

      &.is-active {
        fill: $color-bk;
      }
    }
  }

  .gnav {
    margin-left: auto;

    &__list {
      display: flex;
      align-items: flex-start;
      @include media(sp) {
        margin-right: 30px;
      }
    }

    &__item {
      position: relative;
      padding: 0 40px;
      border-right: 1px solid $color-wh;
      font-size: 1.8rem;
      @include media(pc-s) {
        padding: 0 resp(40px);
        font-size: resp(1.8rem);
      }
      @include media(sp) {
        padding: 0;

        &:nth-child(-n+3) {
          display: none;
        }
      }

      & > a {
        display: block;

        &:hover {
          text-decoration: underline;
        }
      }

      &:last-child {
        padding-right: 46px;
        border-right: none !important;
        @include media(pc-s) {
          padding-right: resp(46px);
        }
        @include media(sp) {
          padding-right: 0;
        }
      }
    }
  }
}


/* .drawerBtn
------------------------------------------------ */
.drawerBtn {
  position: relative;
  display: block;
  width: 25px;
  height: 17px;
  margin-right: 4em;
  z-index: 101;
  cursor: pointer;
  @include media(pc-s) {
    width: resp(25px);
    height: resp(17px);
  }
  @include media(sp) {
    width: 25px;
    height: 17px;
    margin-right: 0;
  }

  &::after {
    content: 'MENU';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
    padding-left: 18px;
    font-size: 2.2rem;
    font-family: $font-en;
    color: $color-wh;
    transition: .4s;
    @include media(pc-s) {
      padding-left: resp(18px);
      font-size: resp(2.2rem);
    }
    @include media(sp) {
      display: none;
    }
  }

  & > span {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 1px;
    background-color: $color-wh;
    transform-origin: 50%;
    transition: .4s;

    &:nth-of-type(1) {
      transform: rotate(0) translateY(-8px);
      @include media(pc-s) {
        transform: rotate(0) translateY(-7px);
      }
      @include media(sp) {
        transform: rotate(0) translateY(-8px);
      }
    }
    &:nth-of-type(3) {
      transform: rotate(0) translateY(8px);
      @include media(pc-s) {
        transform: rotate(0) translateY(7px);
      }
      @include media(sp) {
        transform: rotate(0) translateY(8px);
      }
    }
  }

  &.is-active {

    &::after {
      content: 'ClOSE';
      color: $color-bk;
    }

    & > span {
      background-color: #000;

      &:nth-of-type(1) {
        transform: translateY(0) rotate(-45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(0) rotate(45deg);
      }
    }
  }
}


/* .drawerContent
------------------------------------------------ */
.drawerContent {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $color-wh;
  transition: .5s;
  visibility: hidden;
  opacity: 0;
  overflow: scroll;
  z-index: 100;

  &.is-open {
    transition: .5s;
    visibility: visible;
    opacity: 1;

    .drawerBtn::after {
      content: 'CLOSE';
      color: $color-bk;
    }
  }

  .drawerContent-inner {
    display: flex;
    justify-content: center;
    @include media(sp) {
      display: block;
    }
  }

  &__list {

    &.-first {
      margin-right: 148px;
      @include media(pc-s) {
        margin-right: resp(148px);
      }
      @include media(sp) {
        margin-right: 0;
        margin-top: 26%;
        margin-bottom: 28px;
      }
    }
  }

  &__item {
    position: relative;
    margin-bottom: 44px;
    padding-left: 14px;
    font-size: 1.8rem;
    color: $color-bk;
    @include media(pc-s) {
      margin-bottom: resp(44px);
      padding-left: resp(14px);
      font-size: resp(1.8rem);
    }
    @include media(sp) {
      margin-bottom: 28px;
      padding-left: 12px;
      font-size: 1.4rem;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      background-color: $color-bk;
      border-radius: 50%;
      @include media(pc-s) {
        width: resp(4px);
        height: resp(4px);
      }
      @include media(sp) {
        width: 3px;
        height: 3px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    & > a {
      display: block;

      &:hover {
        color: $color-main;
        text-decoration: underline;
      }
    }
  }
}


/* スマホ横
------------------------------------------------ */
@media only screen and (orientation:landscape) and (max-width:813px){
  .drawerContent-inner {
    display: flex !important;
  }

  .drawerContent__list {
    margin-top: 70px !important;

    &.-first {
      margin-right: 60px;
    }
  }
}
