/* loadAnimation
------------------------------------------------- */
.load {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100vh;
  background-color: $color-main;
  &.is-hidden {
    animation: SlideOut .8s cubic-bezier(.645, .045, .355, 1) forwards;
  }
}

.dummy {
  position: relative;
  height: 100vh;
  visibility: visible;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba($color-bk,.8);
    opacity: 0;
    transition: opacity .8s;
    pointer-events: none;
  }

  &.is-test {

    &::before {
      opacity: 1;
    }

    & .dummy__text {
      opacity: .3;
    }
  }

  &__container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 205;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.7s ease;
    &.is-show {
      opacity: 1;
    }
  }

  &__text {
    transform: translateY(-44px);
    width: 100%;
    font-size: 5.6rem;
    font-weight: bold;
    line-height: 1.7;
    letter-spacing: .2em;
    text-align: center;
    font-feature-settings: "palt";
    z-index: 201;
    transition: opacity .7s ease;
    @include media(pc-s) {
      font-size: resp(5.6rem);
    }
    @include media(sp) {
      font-size: 2.4rem;
      line-height: 1.8;
    }

    & > .en {
      display: block;
      margin-top: 26px;
      @include media(pc-s) {
        margin-top: resp(26px);
      }
      @include media(sp) {
        margin: 14px auto 0;
        width: 290px;
      }

      & > img {
        width: 390px;
        @include media(pc-s) {
          width: resp(390px);
        }
        @include media(sp) {
          width: 176px;
        }
      }
    }

    &.is-show {
      opacity: 1;
    }
  }

}
