/* Webマーケティング
------------------------------------------------- */
.p-markeList {
  margin-bottom: 160px;
  @include media(pc-s) {
    margin-bottom: resp(160px);
  }
  @include media(sp) {
    margin-bottom: 60px;
  }
}


.markeList__container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -86px;
  @include media(pc-s) {
    margin-left: -60px;
  }
  @include media(tb) {
    margin-left: -40px;
  }
  @include media(sp) {
    margin-left: 0;
  }
}


.markeList__item {
  width: calc(50% - 86px);
  margin-bottom: 82px;
  margin-left: 86px;
  @include media(pc-s) {
    width: calc(50% - 60px);
    margin-bottom: resp(82px);
    margin-left: 60px;
  }
  @include media(tb) {
    width: calc(50% - 40px);
    margin-bottom: 50px;
    margin-left: 40px;
  }
  @include media(sp) {
    width: 100%;
    margin-bottom: 0;
    margin-left: 0;

    &:not(:nth-child(1)) {
      margin-top: 36px;
    }
  }

  &:nth-last-child(-n+2) {
    margin-bottom: 0 !important;
  }

  .row {

    .row__image {
      margin-bottom: 30px;
      @include media(pc-s) {
        margin-bottom: resp(30px);
      }
      @include media(sp) {
        margin-bottom: 13px;
      }

      img {
        max-width: none;
        width: 100%;
        @include media(sp) {
          max-width: 100%;
          width: auto;
        }
      }
    }

    .row__title {
      font-size: 2.2rem;
      @include media(pc-s) {
        font-size: resp(2.2rem);
      }
      @include media(sp) {
        font-size: 1.6rem;
      }
    }
  }
}
