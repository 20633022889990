/* Webデザイン
------------------------------------------------- */
.wrapper {

  &.-design {
    max-width: 1280px;
    @include media(pc-s) {
      max-width: 1000px;
    }
    @include media(sp) {

    }
  }
}


.designWork {

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -47px;
    @include media(pc-s) {
      margin-left: -37px;
    }
    @include media(tb) {
      margin-left: -30px;
    }
    @include media(sp) {
      flex-direction: column;
      align-items: center;
      margin-left: 0;
      padding: 0 20px;
    }
  }

  &__item {
    position: relative;
    width: calc(33.333% - 47px);
    margin-bottom: 70px;
    margin-left: 47px;
    transition: .4s;
    @include media(pc-s) {
      width: calc(33.333% - 37px);
      margin-bottom: resp(70px);
      margin-left: 37px;
    }
    @include media(tb) {
      width: calc(33.333% - 30px);
      margin-left: 30px;
    }
    @include media(sp) {
      width: 100%;
      margin-bottom: 0;
      margin-left: 0;

      &:not(:nth-child(1)) {
        margin-top: 50px;
      }
    }

    &:hover {
      opacity: .7;
    }

    .work {

      &__image {
        margin-bottom: 16px;
        @include media(pc-s) {
          margin-bottom: resp(16px);
        }
        @include media(sp) {
          margin-bottom: 10px;
        }
      }

      &__cate {
        margin-bottom: 10px;
        font-size: 1.6rem;
        @include media(pc-s) {
          margin-bottom: resp(10px);
          font-size: resp(1.6rem);
        }
        @include media(sp) {
          margin-bottom: 10px;
          font-size: 1.2rem;
        }
      }

      &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
        font-size: 2.2rem;
        line-height: 1.6;
        text-decoration: underline;
        @include media(pc-s) {
          height: resp(70px);
          font-size: resp(2.2rem);
        }
        @include media(sp) {
          height: 48px;
          font-size: 1.6rem;
        }
      }
    }

    & > a {
      @include linkall;
    }
  }
}
