/* メインの設定(全体)
------------------------------- */

// wrapper
.wrapper {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  @include media(pc-s) {
    padding: 0 5%;
  }
  @include media(tb) {
    padding: 0 30px;
  }
  @include media(sp) {
    padding: 0 20px;
  }
  @include media(se) {
    padding: 0 12px;
  }
}

// main
.main {
  display: block;
}

// .sec {
.sec {
  position: relative;
}

// br関連
.d-sp,
.d-se {
  display: none;
}

@include media(pc-s) {
  .d-sp {
    display: none;
  }
}

@include media(sp) {
  .d-pc, .sp-none {
    display: none;
  }

  .d-sp {
    display: block;
  }
}

@include media(se) {
  .d-se {
    display: block;
  }
}
