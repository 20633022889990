.p-privacy-list {

  &__container {

  }

  &__item {
    padding: 30px 35px;
    border-bottom: 1px dotted $color-glay;
    text-align: left;
    @include media(pc-s) {
      padding: resp(30px) resp(35px);
    }
    @include media(sp) {
      padding: 16px 14px;
    }

    &.-wide {
      padding: 90px 30px;
      @include media(pc-s) {
        padding: resp(90px) resp(30px);
      }
      @include media(sp) {
        padding: 30px 16px;
      }
    }

    &.-bbNone {
      padding-bottom: 0 !important;
      border-bottom: none;
    }

    & .indent {
      padding-left: 1em;
    }
  }
}
