/* contactConfirm
------------------------------------------------- */
#mfp_phase_confirm {

  h4 {
    margin-bottom: 100px;
    font-size: 2rem;
    line-height: 2;

    @include media(pc-s) {
      margin-bottom: resp(100px);
      font-size: resp(2rem);
    }

    @include media(sp) {
      margin-bottom: 50px;
      font-size: 1.3rem;
    }
  }

  #mfp_confirm_table {
    display: block;
    width: 600px;
    margin: 0 auto 120px;
    font-size: 2rem;
    color: $color-bk;

    @include media(pc-s) {
      width: resp(600px);
      margin-bottom: resp(120px);
      font-size: resp(2rem);
    }

    @include media(sp) {
      width: 100%;
      margin-bottom: 60px;
      padding: 0 20px;
      font-size: 1.5rem;
    }

    tr {

      th {
        width: 220px;
        padding-bottom: 50px;
        font-weight: normal;
        line-height: 1.6;
        vertical-align: top;

        @include media(pc-s) {
          width: resp(220px);
          padding-bottom: resp(50px);
        }

        @include media(sp) {
          display: block;
          width: 100%;
          padding-bottom: 6px;
        }

        &#th_contactForm--last {
          padding-bottom: 0;

          @include media(sp) {
            padding-bottom: 6px;
          }
        }
      }

      td {
        padding-bottom: 50px;
        line-height: 1.6;

        @include media(pc-s) {
          padding-bottom: resp(50px);
        }

        @include media(sp) {
          display: block;
          padding-bottom: 34px;
        }

        &#td_contactForm--last {
          padding-bottom: 0 !important;
        }
      }
    }
  }

  .mfp_buttons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    width: 800px;
    margin-bottom: 75px;

    @include media(pc-s) {
      width: resp(800px);
      margin-bottom: resp(75px);
    }

    @include media(sp) {
      display: block;
      width: 100%;
      margin-bottom: 44px;
    }

    .mfp_element_button {
      position: relative;
      display: block;
      width: 46%;
      background-color: $color-main;
      padding: 40px 0;
      font-size: 2.2rem;
      color: $color-wh;

      @include media(pc-s) {
        padding: resp(40px) 0;
        font-size: resp(2.2rem);
      }

      @include media(sp) {
        width: 100%;
        padding: 20px 0;
        font-size: 1.4rem;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-image: url(../images/common/icon/arrow_right01.svg);
        background-repeat: no-repeat;
        width: 36px;
        height: 17px;
        transition: .4s;

        @include media(pc-s) {
          width: resp(36px);
          height: resp(17px);
        }

        @include media(sp) {
          width: 20px;
          height: 11px;
        }
      }

      &:hover {
        opacity: .7;
      }

      &#mfp_button_send {

        &::before {
          right: 16px;
          transform: translateY(-50%);

          @include media(pc-s) {
            right: resp(16px);
          }

          @include media(sp) {
            right: 10px;
          }
        }

        &:hover {
          opacity: .7;
        }
      }

      &#mfp_button_cancel {
        margin-right: 54px;

        @include media(pc-s) {
          margin-right: resp(54px);
        }

        @include media(sp) {
          margin-right: 0;
          margin-bottom: 10px;
        }

        &::before {
          content: '';
          left: 16px;
          transform: translateY(-50%) rotate(180deg);

          @include media(pc-s) {
            left: resp(16px);
          }

          @include media(sp) {
            left: 10px;
          }
        }

        &:hover {
          opacity: .7;
        }
      }
    }
  }
}
