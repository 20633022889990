.l-section-head {
  margin-top: 100px;
  padding-top: 190px;
  @include media(pc-s) {
    margin-top: resp(100px);
    padding-top: resp(190px);
  }
  @include media(sp) {
    margin-top: 70px;
    padding-top: 44px;
  }

  // トップにアイコンあるパターン
  &.-pat01 {
    padding-top: 140px;
    padding-bottom: 100px;
    border-bottom: 1px dotted $color-glay;
    @include media(pc-s) {
      padding-top: resp(140px);
      padding-bottom: resp(100px);
    }
    @include media(sp) {
      padding-top: 40px;
      padding-bottom: 60px;
    }

    &.-bbNone {
      padding-bottom: 0 !important;
      border-bottom: none;
    }
  }

  // 個人情報保護方針、サイトのご利用条件
  &.-pat02 {
    padding-top: 220px;
    padding-bottom: 100px;
    border-bottom: 1px dotted $color-glay;
    @include media(pc-s) {
      padding-top: resp(220px);
      padding-bottom: resp(100px);
    }
    @include media(sp) {
      padding-top: 80px;
      padding-bottom: 60px;
    }
  }

  &__inner {
    text-align: center;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon-box {
    margin-bottom: 40px;
    @include media(pc-s) {
      margin-bottom: resp(40px);
    }
    @include media(sp) {
      margin-bottom: 22px;
    }

    &.-site {
      width: 160px;
      height: 100px;
      @include media(pc-s) {
        width: resp(160px);
        height: resp(100px);
      }
      @include media(sp) {
        width: 121px;
        height: 76px;
      }
    }

    &.-lp {
      width: 194px;
      height: 144px;
      @include media(pc-s) {
        width: resp(194px);
        height: resp(144px);
      }
      @include media(sp) {
        width: 130px;
        height: 97px;
      }
    }

    &.-purpose {
      width: 163px;
      height: 160px;
      @include media(pc-s) {
        width: resp(163px);
        height: resp(160px);
      }
      @include media(sp) {
        width: 112px;
        height: 110px;
      }
    }

    &.-system {
      width: 183px;
      height: 102px;
      @include media(pc-s) {
        width: resp(183px);
        height: resp(102px);
      }
      @include media(sp) {
        width: 140px;
        height: 78px;
      }
    }

    &.-about {
      width: 223px;
      height: 116px;
      @include media(pc-s) {
        width: resp(223px);
        height: resp(116px);
      }
      @include media(sp) {
        width: 160px;
        height: 80px;
      }
    }

    &.-example {
      width: 205px;
      height: 129px;
      @include media(pc-s) {
        width: resp(205px);
        height: resp(129px);
      }
      @include media(sp) {
        width: 155px;
        height: 98px;
      }
    }

    &.-security {
      width: 108px;
      height: 115px;
      @include media(pc-s) {
        width: resp(108px);
        height: resp(115px);
      }
      @include media(sp) {
        width: 83px;
        height: 88px;
      }
    }

    &.-convenience {
      width: 116px;
      height: 128px;
      @include media(pc-s) {
        width: resp(116px);
        height: resp(128px);
      }
      @include media(sp) {
        width: 89px;
        height: 98px;
      }
    }

    &.-introduction {
      width: 141px;
      height: 131px;
      @include media(pc-s) {
        width: resp(141px);
        height: resp(131px);
      }
      @include media(sp) {
        width: 108px;
        height: 100px;
      }
    }

    &.-before {
      width: 93px;
      height: 92px;
      @include media(pc-s) {
        width: resp(93px);
        height: resp(92px);
      }
      @include media(sp) {
        width: 80px;
        height: 78px;
      }
    }
  }

  &__title {
    margin-bottom: 100px;
    @include media(pc-s) {
      margin-bottom: resp(100px);
    }
    @include media(sp) {
      margin-bottom: 60px;
    }

    &.-sm {
      @include media(sp) {
        margin-bottom: 48px;
      }
    }
  }

  &__subtitle {
    margin-bottom: 30px;
    @include media(pc-s) {
      margin-bottom: resp(30px);
    }
    @include media(sp) {

    }
  }

  &__text {
    @include media(sp) {
      text-align: left;
    }
  }

  &__image {
    @include re-m;

    img {
      max-width: none;
      width: 100%;
      height: 400px;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      @include media(pc-s) {
        height: resp(400px);
      }
      @include media(sp) {
        height: 300px;
      }
    }
  }
}
