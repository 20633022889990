// p-intro-list01
.p-intro-list01 {

  &__container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    @include media(pc-s) {
      margin-left: -10px;
    }
    @include media(sp) {
      flex-direction: column;
      margin-left: 0;
    }
  }

  &__card {
    width: calc(33.333% - 16px);
    margin-bottom: 16px;
    margin-left: 16px;
    padding: 50px 0 44px;
    @include media(pc-s) {
      width: calc(33.333% - 10px);
      margin-bottom: 10px;
      margin-left: 10px;
      padding: resp(50px) 0 resp(44px);
    }
    @include media(sp) {
      width: 100%;
      margin-bottom: 12px;
      margin-left: 0;
      padding: 30px 0 24px;
    }

    &:nth-child(odd) {
      background-color: #f4faff;
    }
    &:nth-child(even) {
      background-color: #fffafc;
    }
    &:nth-last-child(-n+3) {
      margin-bottom: 0;
      @include media(sp) {
        margin-bottom: 12px;
      }
    }
  }

  &__title {
    margin-bottom: 36px;
    font-size: 2.4rem;
    font-weight: bold;
    color: $color-main;
    @include media(pc-s) {
      margin-bottom: resp(36px);
      font-size: resp(2.4rem);
    }
    @include media(sp) {
      margin-bottom: 16px;
      font-size: 1.7rem;
    }
  }

  &__text {
    font-size: 1.7rem;
    line-height: 2;
    color: $color-glay;
    @include media(pc-s) {
      font-size: resp(1.7rem);
    }
    @include media(sp) {
      font-size: 1.3rem;
    }
  }
}


// p-intro-list02
.p-intro-list02 {
  padding: 0 145px;
  @include media(pc-s) {
    padding: 0 resp(145px);
  }
  @include media(sp) {
    padding: 0;
  }

  &__container {
    border-top: 1px dotted $color-glay;
    text-align: left;
  }

  &__item {
    padding: 34px 50px 26px;
    border-bottom: 1px dotted $color-glay;
    @include media(pc-s) {
      padding: resp(34px) resp(50px) resp(26px);
    }
    @include media(sp) {
      padding: 22px 22px 18px;
    }

    &:nth-child(odd) {
      background-color: #f4faff;
    }
  }

  &__title {
    margin-bottom: 10px;
    font-size: 2.2rem;
    color: $color-main;
    @include media(pc-s) {
      margin-bottom: resp(10px);
      font-size: resp(2.2rem);
    }
    @include media(sp) {
      padding-left: 1em;
      text-indent: -1em;
      font-size: 1.5rem;
      line-height: 1.5;
    }
  }

  &__text {
    font-size: 1.7rem;
    line-height: 1.6;
    color: $color-glay;
    @include media(pc-s) {
      font-size: resp(1.7rem);
    }
    @include media(sp) {
      font-size: 1.3rem;
    }
  }
}
