/* .cateList
------------------------------------------------- */
.l-cateList {
  max-width: 1000px;
  margin: 0 auto;
  @include media(pc-s) {
    max-width: resp(1000px);
  }
  @include media(tb) {
    max-width: 600px;
  }

  &.-dev {
    margin-bottom: 136px;
    @include media(pc-s) {
      margin-bottom: resp(136px);
    }
    @include media(tb) {
      margin-bottom: 70px;
    }
  }
}

.cateList__container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -110px;
  @include media(pc-s) {
    margin-left: -80px;
  }
  @include media(tb) {
    margin-left: -30px;
  }
  @include media(sp) {
    flex-direction: column;
    margin-left: 0;
  }

  &.-sol {
    .cateList__item {

      &:nth-last-child(-n+3) {
        margin-top: 60px;
        @include media(pc-s) {
          margin-top: resp(60px);
        }
        @include media(sp) {
          margin-top: 60px;
        }
      }
    }
  }
}

.cateList__item {
  width: calc(33.333% - 110px);
  margin-left: 110px;
  @include media(pc-s) {
    width: calc(33.333% - 80px);
    margin-left: 80px;
  }
  @include media(tb) {
    width: calc(33.333% - 30px);
    margin-left: 30px;
  }
  @include media(sp) {
    width: 100%;
    margin-left: 0;

    &:not(:nth-child(1)) {
      margin-top: 60px;
    }
  }
}

.cateBox {
  $this: &;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 184px;
    @include media(pc-s) {
      height: resp(184px);
    }
    @include media(sp) {
      height: auto;
      margin-bottom: 22px;
    }

    #{$this}--01 & {
      width: 180px;
      @include media(pc-s) {
        width: resp(180px);
      }
      @include media(sp) {
        width: 143px;
      }
    }
    #{$this}--02 & {
      width: 197px;
      @include media(pc-s) {
        width: resp(197px);
      }
      @include media(sp) {
        width: 158px;
      }
    }
    #{$this}--03 & {
      width: 162px;
      @include media(pc-s) {
        width: resp(162px);
      }
      @include media(sp) {
        width: 130px;
      }
    }
    #{$this}--04 & {
      width: 160px;
      @include media(pc-s) {
        width: resp(180px);
      }
      @include media(sp) {
        width: 122px;
      }
    }
    #{$this}--05 & {
      width: 238px;
      @include media(pc-s) {
        width: resp(238px);
      }
      @include media(sp) {
        width: 182px;
      }
    }
    #{$this}--06 & {
      width: 194px;
      @include media(pc-s) {
        width: resp(194px);
      }
      @include media(sp) {
        width: 150px;
      }
    }
    #{$this}--07 & {
      width: 183px;
      @include media(pc-s) {
        width: resp(183px);
      }
      @include media(sp) {
        width: 140px;
      }
    }
    #{$this}--08 & {
      width: 233px;
      @include media(pc-s) {
        width: resp(233px);
      }
      @include media(sp) {
        width: 180px;
      }
    }
    #{$this}--09 & {
      width: 140px;
      @include media(pc-s) {
        width: resp(140px);
      }
      @include media(sp) {
        width: 110px;
      }
    }
    #{$this}--10 & {
      width: 205px;
      @include media(pc-s) {
        width: resp(205px);
      }
      @include media(sp) {
        width: 157px;
      }
    }
    #{$this}--11 & {
      width: 108px;
      @include media(pc-s) {
        width: resp(108px);
      }
      @include media(sp) {
        width: 83px;
      }
    }
    #{$this}--12 & {
      width: 102px;
      @include media(pc-s) {
        width: resp(102px);
      }
      @include media(sp) {
        width: 80px;
      }
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 78px;
    margin-bottom: 28px;
    font-size: 2.2rem;
    line-height: 1.8;
    @include media(pc-s) {
      height: resp(78px);
      margin-bottom: resp(28px);
      font-size: resp(2.2rem);
    }
    @include media(sp) {
      height: auto;
      margin-bottom: 20px;
      font-size: 1.6rem;
    }
  }

  &__btn {
    position: relative;
    display: block;
    width: 200px;
    padding: 15px 0;
    border: 1px solid #999899;
    font-size: 1.5rem;
    color: #999899;
    transition: .4s ease-out;
    @include media(pc-s) {
      width: resp(200px);
      padding: resp(15px) 0;
      font-size: resp(1.5rem);
    }
    @include media(sp) {
      max-width: 300px;
      width: 100%;
      padding: 12px 0;
      font-size: 1.6rem;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      background-image: url(../images/common/icon/arrow_right02.svg);
      background-repeat: no-repeat;
      width: 17px;
      height: 9px;
      transition: .4s;
      @include media(pc-s) {
        right: resp(16px);
        width: resp(17px);
        height: resp(9px);
      }
      @include media(sp) {
        right: 12px;
        width: 22px;
        height: 12px;
      }
    }

    &:hover {
      background-color: #999899;
      color: $color-wh;
    }
  }
}
