.text {
  font-size: 2.2rem;
  line-height: 2;
  color: $color-bk;
  @include media(pc-s) {
    font-size: resp(2.2rem);
  }
  @include media(sp) {
    font-size: 1.3rem;

    &.-spBig {
      font-size: 2.2rem;
    }
  }

  // magin
  &.-mb30 {
    margin-bottom: 30px;
    @include media(pc-s) {
      margin-bottom: resp(30px);
    }
  }

  // font-size
  &.-small {
    font-size: 2rem;
    @include media(pc-s) {
      font-size: resp(2rem);
    }
    @include media(sp) {
      font-size: 1.3rem;
    }
  }

  // font-weight
  &.-bold {
    font-weight: bold;
  }

  // color
  &.-gray {
    color: $color-glay;
  }
}
