/* contactThinks
------------------------------------------------- */
.mfp_thanks {

  &__text {
    margin-bottom: 130px;
    font-size: 2rem;
    line-height: 2;
    text-align: center;
    @include media(pc-s) {
      margin-bottom: resp(130px);
      font-size: resp(2rem);
    }
    @include media(sp) {
      margin-bottom: 46px;
      font-size: 1.3rem;
    }
  }

  &__btn {
    position: relative;
    display: block;
    width: 600px;
    background-color: $color-main;
    margin: 0 auto;
    padding: 40px 0;
    font-size: 2.2rem;
    color: $color-wh;
    @include media(pc-s) {
      width: resp(600px);
      padding: resp(40px) 0;
      font-size: 1.5rem;
    }
    @include media(sp) {
      width: 100%;
      padding: 20px 0;
      font-size: 1.6rem;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      background-image: url(../images/common/icon/arrow_right01.svg);
      background-repeat: no-repeat;
      width: 36px;
      height: 17px;
      transition: .4s;
      @include media(pc-s) {
        right: resp(16px);
        width: resp(36px);
        height: resp(17px);
      }
      @include media(sp) {
        right: 20px;
        width: 22px;
        height: 13px;
      }
    }

    &:hover {
      opacity: .7;
    }
  }
}
