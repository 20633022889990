/* トップ
------------------------------------------------- */

/* トップ共通
------------------------------------------------- */
.content {
  position: relative;

  &.-top {
    margin: 0 calc(50% - 50vw);
    padding: 0 calc(50vw - 50%) 176px;
    background-color: $color-main;
    text-align: center;
    @include media(pc-s) {
      padding-bottom: resp(176px);
    }
    @include media(sp) {
      padding-bottom: 100px;
    }
  }
}


/* .topBusiness
------------------------------------------------- */
.topBusiness {
  position: relative;
  padding-top: 120px;
  @include media(pc-s) {
    padding-top: resp(120px);
  }
  @include media(sp) {
    margin-bottom: 88px;
    padding-top: 60px;
  }

  &__title {
    margin-bottom: 145px;
    font-size: 10rem;
    font-family: $font-en;
    @include media(pc-s) {
      margin-bottom: resp(145px);
      font-size: resp(10rem);
    }
    @include media(sp) {
      margin-bottom: 64px;
      font-size: 3.8rem;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -60px;
    @include media(pc-s) {
      margin-left: -40px;
    }
    @include media(tb) {
      margin-left: -30px;
    }
    @include media(sp) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 0;
    }
  }

  &__item {
    width: calc(33.333% - 60px);
    margin-bottom: 120px;
    margin-left: 60px;
    @include media(pc-s) {
      width: calc(33.333% - 40px);
      margin-bottom: resp(120px);
      margin-left: 40px;
    }
    @include media(tb) {
      width: calc(33.333% - 30px);
      margin-left: 30px;
    }
    @include media(sp) {
      width: 100%;
      margin-bottom: 60px;
      margin-left: 0;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    &:nth-last-child(-n+3) {
      margin-bottom: 110px;
      @include media(pc-s) {
        margin-bottom: resp(110px);
      }
      @include media(sp) {
        margin-bottom: 60px;
      }
    }
  }

  .cate {

    //アイコン
    &.-chain {
      .cate__icon {
        width: 110px;
        @include media(pc-s) {
          width: resp(110px);
        }
        @include media(sp) {
          width: 84px;
        }
      }
    }
    &.-design {
      .cate__icon {
        width: 89px;
        @include media(pc-s) {
          width: resp(89px);
        }
        @include media(sp) {
          width: 69px;
        }
      }
    }
    &.-market {
      .cate__icon {
        width: 93px;
        @include media(pc-s) {
          width: resp(93px);
        }
        @include media(sp) {
          width: 72px;
        }
      }
    }
    &.-dev {
      .cate__icon {
        width: 105px;
        @include media(pc-s) {
          width: resp(105px);
        }
        @include media(sp) {
          width: 80px;
        }
      }
    }
    &.-solu {
      .cate__icon {
        width: 84px;
        @include media(pc-s) {
          width: resp(84px);
        }
        @include media(sp) {
          width: 64px;
        }
      }
    }
    &.-consul {
      .cate__icon {
        width: 127px;
        @include media(pc-s) {
          width: resp(127px);
        }
        @include media(sp) {
          width: 98px;
        }
      }
    }

    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2em;
      margin-bottom: 48px;
      font-size: 4.2rem;
      font-family: $font-en;
      line-height: 1.1;
      @include media(pc-s) {
        margin-bottom: resp(48px);
        font-size: resp(4.2rem);
      }
      @include media(sp) {
        margin-bottom: 20px;
        font-size: 2.8rem;
      }
    }

    &__icon {
      height: 78px;
      margin: 0 auto 35px;
      @include media(pc-s) {
        height: resp(78px);
        margin-bottom: resp(35px);
      }
      @include media(sp) {
        height: 60px;
        margin-bottom: 18px;
      }
    }

    &__text {
      margin-bottom: 16px;
      font-size: 2.2rem;
      line-height: 1.7;
      @include media(pc-s) {
        margin-bottom: resp(16px);
        font-size: resp(2.2rem);
      }
      @include media(tb) {
        font-size: 1.4rem;
      }
      @include media(sp) {
        margin-bottom: 18px;
        font-size: 1.6rem;
      }
    }

    &__btn {
      position: relative;
      display: block;
      max-width: 148px;
      margin: 0 auto;
      padding: 12px 0 10px;
      border-bottom: 1px solid $color-wh;
      font-size: 1.9rem;
      font-family: $font-en;
      text-align: left;
      @include media(pc-s) {
        max-width: resp(148px);
        padding-top: resp(12px);
        padding-bottom: resp(10px);
        font-size: resp(1.9rem);
      }
      @include media(sp) {
        max-width: 300px;
        padding-top: 12px;
        padding-bottom: 12px;
        border: 1px solid $color-wh;
        font-size: 1.7rem;
        text-align: center;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background-image: url(../images/common/icon/arrow_right01.svg);
        background-repeat: no-repeat;
        width: 23px;
        height: 13px;
        @include media(pc-s) {
          width: resp(23px);
          height: resp(13px);
        }
        @include media(sp) {
          right: 10px;
          width: 21px;
          height: 12px;
        }
      }

      &:hover {
        max-width: 170px;
        @include media(pc-s) {
          max-width: resp(170px);
        }
        @include media(sp) {
          max-width: 300px;
        }
      }
    }
  }
}


/* .topLink
------------------------------------------------- */
.topLink {
  padding: 120px 0;
  background-image: url(../images/top/top_bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  @include media(pc-s) {
    padding: resp(120px) 0;
  }
  @include media(sp) {
    background-size: cover;
    margin: 0 calc(50% - 50vw);
    padding: 0 calc(50vw - 50%);
  }

  &__list {
    display: flex;
    margin-left: -50px;
    @include media(pc-s) {
      margin-left: -40px;
    }
    @include media(sp) {
      display: block;
      margin-left: 0;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(50% - 50px);
    height: 370px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-left: 50px;
    @include media(pc-s) {
      width: calc(50% - 40px);
      height: resp(370px);
      margin-left: 40px;
    }
    @include media(sp) {
      width: 100%;
      max-width: 440px;
      height: auto;
      margin: 0 auto;
      padding: 70px 0 48px;

      &:first-child {
        margin-bottom: 15px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($color-bk, .4);
      transition: .3s;
    }

    &.-company {
      background-image: url(../images/top/top_img01.jpg);
    }

    &.-contact {
      background-image: url(../images/top/top_img02.jpg);
    }

    & > a {
      @include linkall;
    }

    &:hover {

      &::before {
        background-color: rgba($color-bk, .7);
      }

      .link__btn {
        &::before {
          transform: translateX(5px) translateY(-50%);
        }
      }
    }
  }

  .link {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      margin-bottom: 40px;
      font-size: 4.6rem;
      font-family: $font-en;
      @include media(pc-s) {
        margin-bottom: resp(40px);
        font-size: resp(4.6rem);
      }
      @include media(sp) {
        margin-bottom: 24px;
        font-size: 2.8rem;
      }
    }

    &__btn {
      position: relative;
      display: block;
      width: 278px;
      padding: 20px 0;
      border: 1px solid $color-wh;
      font-size: 1.8rem;
      @include media(pc-s) {
        width: resp(278px);
        padding: resp(20px) 0;
        font-size: resp(1.8rem);
      }
      @include media(sp) {
        width: 192px;
        padding: 10px 0;
        font-size: 1.6rem;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 13px;
        transform: translateY(-50%);
        background-image: url(../images/common/icon/arrow_right01.svg);
        background-repeat: no-repeat;
        width: 23px;
        height: 13px;
        transition: .3s;
        @include media(pc-s) {
          width: resp(23px);
          height: resp(13px);
        }
        @include media(sp) {

        }
      }
    }
  }
}




/* スマホ横
------------------------------------------------ */
@media only screen and (orientation:landscape) and (max-width:813px){
  .topAbout {
    height: 200vh;
  }
}
