/* scroollBtn
------------------------------------------------- */
.scroollBtn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  display: block;
  z-index: 1;

  &:hover {
    .scroollBtn__text {
      text-shadow: $color-wh 0 0 10px;
      @include media(sp) {
        text-shadow: none;
      }
    }
    .scroollBtn__icon{
      transform: translateY(6px);
      @include media(sp) {
        transform: translateY(0);
      }
    }
  }

  &__text {
    margin-bottom: 4px;
    font-size: 1.6rem;
    font-family: $font-en;
    color: $color-wh;
    text-align: center;
    @include media(sp) {
      margin-bottom: 3px;
      font-size: 1.3rem;
    }
  }

  &__icon {
    width: 16px;
    height: 17px;
    margin: 0 auto;
    transition: transform .4s;
    @include media(sp) {
      width: 12px;
      height: 12px;
    }
  }
}
