/* システムデベロップメント
------------------------------------------------- */
.p-devText {
  margin-bottom: 130px;
  @include media(pc-s) {
    margin-bottom: resp(130px);
  }
  @include media(tb) {
    margin-bottom: 66px;
  }
}

.dev-text {
  font-size: 2.2rem;
  color: $color-glay;
  @include media(pc-s) {
    font-size: resp(2.2rem);
  }
  @include media(tb) {
    font-size: 1.3rem;
  }
}
