.l-section {
  position: relative;

  &__inner {

    &.-intro {
      padding-top: 70px;
      @include media(pc-s) {
        padding-top: resp(70px);
      }
      @include media(sp) {
        padding-top: 0;
      }
    }
  }

  &.-sm {
    padding: 100px 0;
    border-bottom: 1px dotted $color-glay;
    @include media(pc-s) {
      padding: resp(100px) 0;
    }
    @include media(sp) {
      padding: 60px 0;
    }
  }

  &.-last {
    padding-bottom: 0 !important;
    border-bottom: none;
  }

  &.-blue {
    @include re-p;
    background-color: $color-sub;
    margin-top: 430px;
    padding-bottom: 200px;
    @include media(pc-s) {
      margin-top: resp(430px);
      padding-bottom: resp(200px);
    }
    @include media(tb) {
      margin-top: 260px;
    }
    @include media(sp) {
      margin-top: 150px;
      padding-top: 60px;
      padding-bottom: 100px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      transform: skewY(-10deg);
      transform-origin: top left;
      width: 100%;
      height: 460px;
      background-color: $color-sub;
      z-index: -1;
    }
  }

  &.-bor {
    margin-bottom: 110px;
    padding: 90px 0;
    border-top: 1px solid $color-glay;
    border-bottom: 1px solid $color-glay;
    @include media(pc-s) {
      margin-bottom: resp(110px);
      padding: resp(90px) 0;
    }
    @include media(sp) {
      margin-bottom: 60px;
      padding: 58px 0;
    }
  }

  &.-btop {
    margin-top: 80px;
    padding-top: 100px;
    border-top: 1px solid $color-glay;
    @include media(pc-s) {
      margin-top: resp(80px);
      padding-top: resp(100px);
    }
    @include media(sp) {
      margin-top: 0;
      padding-top: 90px;
      border-top: none;
    }
  }

  &.-bbNone {
    padding-bottom: 0 !important;
    border-bottom: none;
  }
}
