.l-lowerText {
  margin-bottom: 100px;
  @include media(pc-s) {
    margin-bottom: resp(100px);
  }
  @include media(sp) {
    margin-bottom: 60px;
  }

  &.-marke {
    margin-bottom: 130px;
    @include media(pc-s) {
      margin-bottom: resp(130px);
    }
    @include media(sp) {
      margin-bottom: 60px;
    }
  }
}
