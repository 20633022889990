/*ここに変数を定義*/

//font-family
$font: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'Hiragino Sans', 'ヒラギノ角ゴシック', sans-serif;
$font-en: 'Affogato-Medium', sans-serif;

//font-size
$font-size: 10px;

// カラー
$color-bk: #040000;
$color-wh: #fff;
$color-main: #00678f;
$color-sub: #f2fafe;
$color-glay: #727171;

// clearfix  ※floatを使用した親要素に
@mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

// aタグの範囲を全体へ ※親要素にpoition: reletive;
@mixin linkall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// position-center ※親要素にpoition: reletive;
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//.wrapperから出したい(width:100%にしたい)場合ネガティブマージンを使う。
@mixin re-m {
  margin: 0 calc(50% - 50vw);
}

//.wrapperから出すがpaddingは.wrapperに合わせたい場合(主に背景色だけ100%に使う事が多い)
@mixin re-p {
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%);
}

// ストライプ
//pxの単位を付ける関数
@function px($target, $size: 1) {
  @return $target * ($size * 1px);
}

@mixin stripe($deg: 0, $color1: rgba(red,0.4), $color2: rgba(blue,.4),$size: 50) {
  $naname: 45, 135, 225, 315, -45, -135, -225, -315;
  $yoko: 0, 90, 180, 270, 360, -90, -180, -270, -360;
  @if index($naname, $deg)
  {
    background-image: repeating-linear-gradient(
      ($deg * 1deg),
      $color1 0,
      $color1 (percentage(1/3) - 0.05%),
      $color2 percentage(1/3),
      $color2 (percentage(2/3) - 0.05%),
      $color1 percentage(2/3),
      $color1 (percentage(3/3) - 0.05%),
      $color2 percentage(3/3),
      $color2 percentage(4/3)
    );
    background-repeat: repeat;
    background-size: px($size) px($size);
  }
  @if index($yoko, $deg)
  {
    background-image: repeating-linear-gradient(
      ($deg * 1deg),
      $color1 0,
      $color1 (percentage(1/2) - 0.05%),
      $color2 percentage(1/2),
      $color2 percentage(2/2)
    );
    background-repeat: repeat;
    background-size: px($size) px($size);
  }
}
