/* contactForm
------------------------------------------------- */
.l-contactForm {
  display: flex;
  justify-content: center;
}


.contactForm {
  width: 100%;

  &__container {
    display: flex;
    align-items: center;
    width: 810px;
    margin: 0 auto;
    margin-bottom: 60px;

    @include media(pc-s) {
      width: resp(810px);
      margin-bottom: resp(60px);
    }

    @include media(sp) {
      display: block;
      width: 100%;
      margin-bottom: 46px;
    }

    &.-last {
      align-items: flex-start;
      margin-bottom: 120px;

      @include media(pc-s) {
        margin-bottom: resp(120px);
      }

      @include media(sp) {
        margin-bottom: 54px;
      }

      .contactForm__title {
        padding-top: 5px;

        @include media(pc-s) {
          padding-top: resp(5px);
        }

        @include media(sp) {
          padding-top: 0;
        }
      }
    }

    input,
    select,
    textarea {
      width: 100%;
      padding: 8px 14px;
      border: 1px solid #c9caca;
    }
  }

  &__title {
    width: 26%;
    font-size: 2rem;
    text-align: left;

    @include media(pc-s) {
      width: 26%;
      font-size: 1.5rem;
    }

    @include media(sp) {
      width: 100%;
      margin-bottom: 8px;
      font-size: 1.5rem;
    }
  }

  &__input {
    flex: 1;

    &>input {
      height: 40px;

      @include media(sp) {}
    }

    select {
      height: 40px;
      border-radius: 0;
      appearance: none;
      cursor: pointer;
    }

    &>textarea {
      margin-top: 20px;
      line-height: 1.6;
    }
  }

  &__select {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 2px);
      right: 16px;
      transform: translateY(-50%) rotate(45deg);
      display: inline-block;
      width: 10px;
      height: 10px;
      border-bottom: 2px solid $color-main;
      border-right: 2px solid $color-main;
    }
  }

  .mfp_buttons {
    position: relative;
    display: inline-block;
    margin-bottom: 100px;
    transition: .4s;

    @include media(pc-s) {
      margin-bottom: resp(100px);
    }

    @include media(sp) {
      width: 100%;
      margin-bottom: 44px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      background-image: url(../images/common/icon/arrow_right01.svg);
      background-repeat: no-repeat;
      width: 36px;
      height: 17px;
      transition: .4s;

      @include media(pc-s) {
        right: resp(16px);
        width: resp(36px);
        height: resp(17px);
      }

      @include media(sp) {
        right: 20px;
        width: 22px;
        height: 13px;
      }
    }

    .submitBtn {
      width: 600px;
      background-color: $color-main;
      margin: 0 auto;
      padding: 40px 0;
      font-size: 2.2rem;
      color: $color-wh;
      cursor: pointer;

      @include media(pc-s) {
        width: resp(600px);
        padding: resp(40px) 0;
        font-size: 1.5rem;
      }

      @include media(sp) {
        width: 100%;
        padding: 20px 0;
        font-size: 1.6rem;
      }
    }

    &:hover {
      opacity: .7;
    }
  }
}


.l-contactText {}

.contact-text {
  font-size: 2rem;
  line-height: 2;
  color: $color-bk;

  @include media(pc-s) {
    font-size: resp(2rem);
  }

  @include media(tb) {
    font-size: 1.3rem;
  }

  @include media(sp) {
    text-align: left;
  }
}
