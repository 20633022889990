.l-grid {
  display: flex;
  flex-wrap: wrap;
  @include media(sp) {
    flex-direction: column;
    align-items: center;
  }

  &__cell {

    &.-flex1 {
      flex: 1;
    }
  }

  &__imgBox {
    max-width: 400px;
    margin-right: 47px;
    @include media(pc-s) {
      max-width: resp(400px);
      margin-right: resp(47px);
    }
    @include media(sp) {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 26px;
    }
  }

  &__titleBox {
    margin-bottom: 16px;
    text-align: left;
    @include media(pc-s) {
      margin-bottom: resp(16px);
    }
    @include media(sp) {
      margin-bottom: 10px;
    }
  }

  &__textBox {
    text-align: left;
  }
}
