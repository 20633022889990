//@keyframesから定義するanimationの設定
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes SlideOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
    display: none;
  }
}
