.l-section-privacy {
  padding-top: 140px;
  @include media(pc-s) {
    padding-top: resp(140px);
  }
  @include media(sp) {
    padding-top: 70px;
  }

  &__inner {

  }

  &__title {
    margin-bottom: 90px;
    @include media(pc-s) {
      margin-bottom: resp(90px);
    }
    @include media(sp) {
      margin-bottom: 40px;
    }
  }

  &__text {
    padding-bottom: 60px;
    border-bottom: 1px dotted $color-glay;
    text-align: left;
    @include media(pc-s) {
      padding-bottom: resp(60px);
    }
    @include media(sp) {
      padding-bottom: 30px;
    }
  }

  &__address {
    padding: 30px 35px;
    border-bottom: 1px dotted $color-glay;
    text-align: left;
    @include media(pc-s) {
      padding: resp(30px) resp(35px);
    }
    @include media(sp) {
      padding: 16px 14px;
    }
  }
}
